import { Injectable } from '@angular/core';
import { Features } from '@app/enums';
import { FeatureService } from '@app/startup/feature.service';
import { FileUploader } from 'ng2-file-upload';
import { arrayOf, normalize } from 'normalizr';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiGateway } from '../../../api/ApiGateway.service';
import { AuthService } from '../../auth/auth.service';
import { UnsafeAction as Action } from '../../interfaces';
import { assignSchemaEntity } from '../../shared/assign';
import { reformatEntityResponse, reformatListResponse } from '../../shared/entity.helper';
import { EmployeeSchema, UserFileSchema, UserNoteSchema } from '../../shared/schemas';
import { Balance, EmployeeTeamSavePayload, TimeDistributionResponse, UserAbsencePolicies } from './employee.model';

interface StatOptions {
  minDate: string;
  maxDate: string;
  groupBy?: string;
}

@Injectable()
export class EmployeeApi {
  private readonly endpoint = 'users';

  public constructor(
    private gateway: ApiGateway,
    private authService: AuthService,
    private readonly featureService: FeatureService,
  ) {}

  public load(): Observable<any> {
    const params = { allow_hidden: '1', allow_all: '1' };

    if (this.featureService.isFeatureActivated(Features.TMP_APPROVE_TIMESHEETS_SHOW_EXTERNAL_EMPLOYEES)) {
      params['allow_all_users'] = true;
    }

    return this.gateway.get(this.endpoint, { params }).pipe(
      map((res) => reformatListResponse('User', res)),
      map((data) => normalize(data, arrayOf(EmployeeSchema), { assignEntity: assignSchemaEntity })),
    );
  }

  public absencePolicies(userIds: string[], date: string): Observable<UserAbsencePolicies[]> {
    return this.gateway.post(`${this.endpoint}/absence/policies`, { userIds, date });
  }

  public add(data): Observable<any> {
    return this.gateway.post(this.endpoint, data).pipe(
      map((res) => reformatEntityResponse('User', res)),
      map((res) => normalize(res, EmployeeSchema, { assignEntity: assignSchemaEntity })),
    );
  }

  public update(id: string, data): Observable<any> {
    return this.gateway.put(`${this.endpoint}/${id}`, data).pipe(
      map((res) => reformatEntityResponse('User', res)),
      map((res) => normalize(res, EmployeeSchema, { assignEntity: assignSchemaEntity })),
    );
  }

  public activate(id: string): Observable<any> {
    return this.gateway.put(`${this.endpoint}/${id}/activate`, undefined).pipe(
      map((res) => reformatEntityResponse('User', res)),
      map((res) => normalize(res, EmployeeSchema, { assignEntity: assignSchemaEntity })),
    );
  }

  public saveMany(employees, action, options?): Observable<any> {
    return this.gateway.post(`${this.endpoint}/batch`, employees, options, action).pipe(
      map((res) => reformatListResponse('User', res)),
      map((res) => normalize(res, arrayOf(EmployeeSchema), { assignEntity: assignSchemaEntity })),
    );
  }

  public fetch(id: string): Observable<any> {
    return this.gateway.get(`${this.endpoint}/${id}`).pipe(
      map((res) => reformatEntityResponse('User', res)),
      map((data) => normalize(data, EmployeeSchema, { assignEntity: assignSchemaEntity })),
    );
  }

  public remove(id: string, data = {}, options?): Observable<any> {
    return this.gateway.delete(`${this.endpoint}/${id}`, options, undefined, data);
  }

  public anonymize(id: string): Observable<any> {
    return this.gateway.delete(`${this.endpoint}/${id}/anonymize`);
  }

  public stats(userId: string, statOptions: StatOptions): Observable<any> {
    const params = {
      min_date: statOptions.minDate,
      max_date: statOptions.maxDate,
    };

    if (statOptions.groupBy) {
      params['group_by'] = statOptions.groupBy;
    }

    return this.gateway.get(`${this.endpoint}/stats/${userId}`, {
      params,
    });
  }

  public loadFiles(employeeId: string, dispatchStart?: Action): Observable<any> {
    return this.gateway.get(`${this.endpoint}/${employeeId}/files`, undefined, dispatchStart).pipe(
      map((res) => reformatListResponse('Attachment', res)),
      map((data) => normalize(data, arrayOf(UserFileSchema), { assignEntity: assignSchemaEntity })),
    );
  }

  public addFile(employeeId: string, attachmentData, dispatchStart?: Action) {
    return this.gateway.post(`${this.endpoint}/${employeeId}/files`, attachmentData, undefined, dispatchStart).pipe(
      map((res) => reformatEntityResponse('Attachment', res)),
      map((data) => normalize(data, UserFileSchema, { assignEntity: assignSchemaEntity })),
    );
  }

  public updateFile(attachmentId, data, dispatchStart?: Action) {
    return this.gateway.put(`attachments/${attachmentId}`, data, undefined, dispatchStart).pipe(
      map((res) => reformatEntityResponse('Attachment', res)),
      map((attachment) => normalize(attachment, UserFileSchema, { assignEntity: assignSchemaEntity })),
    );
  }

  public loadNotes(employeeId, dispatchStart?: Action): Observable<any> {
    return this.gateway.get(`${this.endpoint}/${employeeId}/notes`, undefined, dispatchStart).pipe(
      map((res) => reformatListResponse('UserNote', res)),
      map((data) => normalize(data, arrayOf(UserNoteSchema), { assignEntity: assignSchemaEntity })),
    );
  }

  public addNote(employeeId, userNote, dispatchStart?: Action) {
    return this.gateway.post(`${this.endpoint}/${employeeId}/notes`, userNote, undefined, dispatchStart).pipe(
      map((res) => reformatEntityResponse('UserNote', res)),
      map((data) => normalize(data, UserNoteSchema, { assignEntity: assignSchemaEntity })),
    );
  }

  public updateNote(userNoteId, userNote, dispatchStart?: Action) {
    return this.gateway.put(`user_notes/${userNoteId}`, userNote, undefined, dispatchStart).pipe(
      map((res) => reformatEntityResponse('UserNote', res)),
      map((attachment) => normalize(attachment, UserNoteSchema, { assignEntity: assignSchemaEntity })),
    );
  }

  public deleteNote(userNoteId, dispatchStart?: Action) {
    return this.gateway.delete(`user_notes/${userNoteId}`, undefined, dispatchStart);
  }

  public saveTeams(employeeId: string, payload: EmployeeTeamSavePayload, dispatchStart?: Action) {
    return this.gateway
      .put(`employees/${employeeId}/teams`, { User: { departments: payload } }, undefined, dispatchStart)
      .pipe(
        map((res) => reformatEntityResponse('User', res)),
        map((data) => normalize(data, EmployeeSchema, { assignEntity: assignSchemaEntity })),
      );
  }

  public savePermissions(employeeId, permissions, dispatchStart?: Action) {
    return this.gateway
      .put(
        `${this.endpoint}/${employeeId}/permissions`,
        {
          Department: permissions,
        },
        undefined,
        dispatchStart,
      )
      .pipe(
        map((res) => reformatEntityResponse('User', res)),
        map((data) => normalize(data, EmployeeSchema, { assignEntity: assignSchemaEntity })),
      );
  }

  public getTimeDistribution(employeeId: string, year: string): Observable<TimeDistributionResponse> {
    return this.gateway.get(`employees/${employeeId}/time_distribution?year=${year}`);
  }

  public getBalances(employeeId: string, year: string): Observable<Balance[]> {
    return this.gateway.get(`employees/${employeeId}/timeOff/balances/details/${year}`);
  }

  /**
   * Send message to selected employees.
   *
   * @param messageData
   * @returns {Observable<any>}
   */
  public sendMessage(messageData) {
    return this.gateway.post(`${this.endpoint}/message`, messageData);
  }

  /**
   * Send password reset to selected employees.
   *
   * @returns {Observable<any>}
   */
  public sendPassword(employeeIds: string) {
    return this.gateway.post(`${this.endpoint}/invite`, employeeIds);
  }

  public resendInvite(employeeIds: string) {
    return this.gateway.post(`${this.endpoint}/invite?resend_invite=1`, employeeIds);
  }

  public getEmployeeAvatarFileUploader(employeeId: string) {
    const url = this.gateway.getFullUrl(`${this.endpoint}/${employeeId}/avatar`);

    const authState = this.authService.getCurrentAuthState();

    const uploader = new FileUploader({
      url,
      autoUpload: true,
      method: 'POST',
      maxFileSize: 5242880,
      disableMultipart: true,
      queueLimit: 1,
      isHTML5: true,
      headers: [
        {
          name: 'X-App-Type',
          value: 'Web App',
        },
        {
          name: 'Accept',
          value: 'application/json',
        },
        {
          name: 'X-App-Account',
          value: authState.account_id,
        },
        {
          name: 'X-App-User',
          value: authState.user_id,
        },
        {
          name: 'X-SHIFTBASE-CSRF-PROTECTION',
          value: '1',
        },
      ],
      allowedMimeType: ['image/jpg', 'image/jpeg', 'image/png'],
    });

    return uploader;
  }

  public deleteAvatar(employeeId: string): Observable<void> {
    return this.gateway.delete(`${this.endpoint}/${employeeId}/avatar`);
  }
}
