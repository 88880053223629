import { Injectable } from '@angular/core';
import { TrackingService } from '@app/services/tracking.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { compose, createSelector, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of as observableOf, throwError as observableThrowError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { AppState } from '../index';
import { MessageAction } from '../message/message.action';
import { mfaState } from '../mfa/mfa.service';
import { AuthAction } from './auth.action';
import { AuthApi } from './auth.api';
import { AccountOption, AuthRequest, AuthState } from './auth.model';

export const originOptions = {
  web_app: _('Web application'),
  mobile_app: _('Mobile app'),
  mobile: _('Mobile web'),
  terminal: _('Terminal'),
  api: _('API'),
};

export interface Countries {
  [countryCode: string]: string;
}

@Injectable()
export class AuthService {
  public state: Observable<AuthState>;
  private snapshot: AuthState;
  private _redirectUrl: string;

  public get redirectUrl() {
    return this._redirectUrl;
  }

  public set redirectUrl(url: string) {
    if (url === '/logout') {
      this._redirectUrl = null;
    } else {
      this._redirectUrl = url;
    }
  }

  public constructor(
    private store: Store<AppState>,
    private api: AuthApi,
    private translate: TranslateService,
    private trackingService: TrackingService,
  ) {
    this.state = store.select(authState);
    this.state.subscribe((state) => (this.snapshot = state));
  }

  public getCurrentAuthState() {
    return this.snapshot;
  }

  public selectAccountOptions(): Observable<AccountOption[]> {
    return this.state.pipe(map((data) => data.accounts));
  }

  public login(payload: AuthRequest) {
    return this.api.login(payload, AuthAction.login(payload)).pipe(
      map((response) => {
        this.store.dispatch(AuthAction.loginSuccess(response));
        return response;
      }),
      catchError((response) => {
        if (response.status === 409) {
          this.store.dispatch(AuthAction.loginChooseAccount(response.error));
          this.store.dispatch(MessageAction.notification(this.translate.instant('Choose an account')));
        } else {
          this.store.dispatch(AuthAction.loginFailed());
        }
        return observableThrowError(response);
      }),
    );
  }

  public logout() {
    return this.api
      .logout(AuthAction.logout())
      .toPromise()
      .then(() => {
        this.store.dispatch(AuthAction.logoutSuccess());
        this.store.dispatch(AuthAction.clearSession());
      })
      .catch((response) => {
        this.store.dispatch(AuthAction.logoutFailed());
        return observableThrowError(response);
      });
  }

  public signUp(payload) {
    return this.api.signUp(payload, AuthAction.signUp()).pipe(
      map((response) => {
        this.store.dispatch(AuthAction.signUpSuccess());
        return response;
      }),
      catchError((response) => {
        this.store.dispatch(AuthAction.signUpFailed());
        return observableThrowError(response);
      }),
    );
  }

  // has to be passed a prop instead of feature flag due to circular dependency
  // updatedTracking should be removed when TMP_NEW_ONBOARDING_V2 is removed
  public signUpNoVerify(payload) {
    return this.api.signUp(payload, AuthAction.signUp()).pipe(
      map((response) => {
        this.store.dispatch(AuthAction.signUpNoVerifySuccess(response));
        this.trackingService.event('Signup Completed');
        return response;
      }),
      catchError((response) => {
        this.trackingService.event('Signup Failed', {
          error_api_type: response.status,
          error_api_message: response.statusText,
        });
        this.store.dispatch(AuthAction.signUpNoVerifyFailed());
        return observableThrowError(response);
      }),
    );
  }

  public verifySignUp(payload) {
    return this.api.verifySignUp(payload, AuthAction.verifySignUp()).pipe(
      map((response) => {
        this.store.dispatch(AuthAction.verifySignUpSuccess(response));
        return response;
      }),
      catchError((response) => {
        this.store.dispatch(AuthAction.verifySignUpFailed());
        return observableThrowError(response);
      }),
    );
  }

  public forgotPassword(payload) {
    return this.api.forgotPassword(payload, AuthAction.forgotPassword()).pipe(
      map((response) => {
        this.store.dispatch(AuthAction.forgotPasswordSuccess());
        return response;
      }),
      catchError((response) => {
        this.store.dispatch(AuthAction.forgotPasswordFailed());
        return observableThrowError(response);
      }),
    );
  }

  public changePassword(payload) {
    return this.api.changePassword(payload, AuthAction.changePassword()).pipe(
      tap(() => this.store.dispatch(AuthAction.changePasswordSuccess())),
      catchError((response) => {
        this.store.dispatch(AuthAction.changePasswordFailed());
        return observableThrowError(response);
      }),
    );
  }

  public changeEmail(payload) {
    return this.api.changeEmail(payload, AuthAction.changeEmail()).pipe(
      map(() => this.store.dispatch(AuthAction.changeEmailSuccess(payload))),
      catchError((response) => {
        this.store.dispatch(AuthAction.changeEmailFailed());
        return observableThrowError(response);
      }),
    );
  }

  public changePinCode() {
    return this.api.changePinCode();
  }

  public getPinCode() {
    return this.api.getPinCode();
  }

  public resetPassword(payload) {
    return this.api.resetPassword(payload, AuthAction.resetPassword()).pipe(
      map((response) => {
        this.store.dispatch(AuthAction.resetPasswordSuccess(response));
        return response;
      }),
      catchError((response) => {
        this.store.dispatch(AuthAction.resetPasswordFailed());
        return observableThrowError(response);
      }),
    );
  }

  public load() {
    this.store.dispatch(AuthAction.load());

    return this.api.status().pipe(
      map((response) => {
        this.store.dispatch(AuthAction.loadSuccess(response));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(AuthAction.loadFailed());
        return observableThrowError(response);
      }),
    );
  }

  public sessions() {
    return this.api.sessions().pipe(catchError((response) => observableThrowError(response)));
  }

  public removeSessions(userId, data) {
    return this.api.removeSessions(userId, data).pipe(catchError((response) => observableThrowError(response)));
  }

  public sendVerificationMail() {
    return this.api.sendVerificationMail();
  }

  public fetchCountries(language: string, observeResponse: boolean = false): Observable<Countries> {
    return this.api.fetchCountries(language, observeResponse).pipe(
      map((response) => response),
      catchError((response) => observableThrowError(response)),
    );
  }
}

export const authState = (state: AppState) => state.auth;

export const isAuthenticated = createSelector(authState, mfaState, (authState, mfaState) => {
  if (!authState.authenticated) {
    return false;
  } else {
    if (mfaState.mfa_provider === null) {
      if (mfaState.mfa_enforced) {
        return false;
      } else {
        return true;
      }
    } else {
      if (mfaState.mfa_logged_in) {
        return true;
      } else {
        return false;
      }
    }
  }
});

export const getAuthenticatedUserId = compose((state: AuthState) => state.user_id, authState);

export const getAuthenticatedUserEmail = compose((state: AuthState) => state.email, authState);

export const getAuthenticatedDepartmentIds = compose((state: AuthState) => state.departments, authState);

export const getAuthenticatedAccountId = compose((state: AuthState) => state.account_id, authState);

export const getAuthenticatedStatus = compose((state: AuthState) => state.status, authState);

export const getAuthenticatedPermissions = compose((state: AuthState) => state.permissions, authState);

export const getAuthenticatedAvailablePermissions = compose(
  (state: AuthState) => state.availablePermissions,
  authState,
);

export const getAuthRefreshedAt = compose((state: AuthState) => state.refreshed_at, authState);
export const getAuthLoading = compose((state: AuthState) => state.loading, authState);

export const getUserType = compose((state: AuthState) => state.userType, authState);

export const getRefinerSignature = compose((state: AuthState) => state.refinerSignature, authState);
